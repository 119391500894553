import { AUTH_ACTIONS, NAV_ACTIONS } from "../actions";
import { SET_ERROR, AUTH_MUTATIONS, SET_PAGE_READ_ONLY } from "../mutations";
import { ACTION_WITH_COMMIT } from "../helpers";
import { JwtService, Identity } from "../../shared/services";
import ActiveUserApi from "../../api/activeUser";
import UsersApi from "../../api/users";
import GridStatesApi from "../../api/gridStates";
import Session from "../../services/session.service";
import AuthenticationApi from "../../api/authentication";
import SystemsApi from "../../api/systems";
import { useLicenseStore } from "./license";

const setWalkMeVariables = (settings, tenantName) => {
    let userLogin = _.get(settings, "user.login", null) || "LOGIN_UNDEFINED";
    let hz_userID = `${userLogin}:${tenantName}`;
    let hz_userRole = _.getDelimitedNumbers(settings, "user.onboardingRoleIDs");

    window.hz_userID = hz_userID;
    window.hz_userRole = hz_userRole;

    return { hz_userID, hz_userRole };
};

const state = {
    isAuthenticated: false,
    identityToken: "",
    identityTokenParsed: {},
    system: {},
    session: {},
    clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    walkMeVarReference: {}
};

const getters = {
    identityToken: state => {return state.identityToken;},
    identityTokenParsed: state => {return state.identityTokenParsed;},
    tenantId: state => {
        //tenantId will eventually be a separate unique value obtained from the token
        return "";
    },
    tenantName: state => {
        let returnValue;
        if (state && state.identityTokenParsed && state.identityTokenParsed.azp) {
            returnValue = state.identityTokenParsed.azp.replace("-ui", "");
        }
        return returnValue || 'notenantidentified';
        // If wanting to "locally" test as a pure tenant instead of the "dev" tenant (which has broader access by necessity).
        //return "rqone";
    },
    isDevelopment: (state, getters) => {
        return getters.tenantName == "dev";
    },
    tenantTimeZone: state => {
        let returnValue;
        if (state && state.identityTokenParsed && state.identityTokenParsed.tenantTimeZone) {
            returnValue = _.last(_.split(state.identityTokenParsed.tenantTimeZone, ','));
        }

        if (!returnValue){
            returnValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        return returnValue;
    },
    clientTimeZone: state => {return state.clientTimeZone;},
    tokenExpirationTimeout: state => {
        let iat =  _.getNumber(state.identityTokenParsed, 'iat', 0);
        let exp = _.getNumber(state.identityTokenParsed, 'exp', 0);
        // This is effectively the millisecond-based setting for "Access Token Lifespan" in Keycloak.
        let sessionTimeout = (exp - iat) * 1000;
        //return 10000;       // 10 second test.
        return sessionTimeout > 0 ? sessionTimeout : 0;
    },
    system: state => {return state.system;},
    session: state => {return state.session;},
    error: state => {return state.error;},
    publisherId: state => state.identityTokenParsed?.publisherId,
    enterpriseId: state => state.identityTokenParsed?.enterpriseId
};

const actions = {

    // Keycloak
    async [AUTH_ACTIONS.SET_IDENTITY_AUTH] ({ commit, state }, token) {
        commit(AUTH_MUTATIONS.SET_IDENTITY_AUTH, token);
        if (state.isAuthenticated) {
            const licenseStore = useLicenseStore();
            await licenseStore.patchFeatures(state.identityTokenParsed?.hznExternalFeaturesTenant, false);
            await licenseStore.patchFeatures(state.identityTokenParsed?.hznExternalFeaturesUser);
        }
        return token;
    },

    async [AUTH_ACTIONS.INITIALIZE_SESSION] ({ state, dispatch, commit }, { token, userSettings}){
        try {
            // Set the token
            await dispatch(AUTH_ACTIONS.SET_IDENTITY_AUTH, token);

            // If no token provided, we will not try to acquire background data.
            if (!state.isAuthenticated) return;

            // Token set. Let's get background information.
            // If this fails, we will purge authorization and, as a consequnce,
            // let the page still load but enforce NO PAGE ACCESS and defaulting to NotAuthorized landing.
            await Promise.all([
                dispatch(AUTH_ACTIONS.SET_USER_SETTINGS, userSettings),
                dispatch(NAV_ACTIONS.GET_UNREAD_ALERT_COUNT),
                //context.dispatch(GET_SYSTEM_DATA),
                //context.dispatch(SYSTEM_ACTIONS.GET_SYSTEM_DEFAULTS),
            ]);

            return true;
        }
        catch(error) {
            commit(AUTH_MUTATIONS.PURGE_AUTH);
            commit(SET_ERROR, error.errorMessage || error.message, { root: true });
            console.error(error);
            return false;
        }
    },

    async [AUTH_ACTIONS.UPDATE_SESSION_USER] (context, data) {
        try {
            let user = await UsersApi.saveUserEntity(data.user, data.changes);
            context.commit(AUTH_MUTATIONS.SET_SESSION_USER, user);
        }
        catch(error) {
            context.commit(SET_ERROR, error.errorMessage || error.message, { root: true });
            throw error;
        }
    },

    [AUTH_ACTIONS.APPEND_USER_DICTIONARY] (context, words) {
        return new Promise((resolve, reject) => {
            let userId = _.getNumber(context, "state.session.userId", null) || 0;
            if (userId === 0) {
                reject("Missing user id");
            } else if (words.length < 1) {
                reject("No words to add");
            } else {
                UsersApi.addDictionaryWords(userId, words)
                    .then(response => {
                        let userDictionary = _.get(context, "state.session.user.dictionaryWords") || [];
                        context.commit(AUTH_MUTATIONS.SET_USER_DICTIONARY, _.concat(userDictionary, words));
                        resolve(true);
                    })
                    .catch(error => {
                        context.commit(SET_ERROR, error.errorMessage || error.message, { root: true });
                        reject(error);
                    });
            }
        });
    },

    async [AUTH_ACTIONS.GET_USER_SETTINGS] ({ commit, dispatch }) {
        try {
            let settings = await UsersApi.getUserSettings();
            return await dispatch(AUTH_ACTIONS.SET_USER_SETTINGS, settings);
        }
        catch(error) {
            commit(SET_ERROR, error.errorMessage || error.message, { root: true });
            throw error;
        }
    },

    async [AUTH_ACTIONS.SET_USER_SETTINGS] ({ commit, state, getters }, settings) {
        try {
            // RQO-27221 - TG - only setting what's stored server side to retain any
            // other values held in localStorage that may not exist on the server yet.
            var gridStates = (await GridStatesApi.getGridStates()) || {};
            _.forEach(gridStates, (val, key) => {
                if(_.isEmpty(val)) return;
                localStorage.setItem(key, val);
            });
        }
        catch(error) {
            console.error("Grid state initialization failed...", error);
        }
        try {
            commit(AUTH_MUTATIONS.SET_SESSION, new Session({settings}));
            let isAppReadOnly = _.some(settings.flags, { name: 'IsAppReadOnly', value: true });
            if (isAppReadOnly) {
                commit(SET_PAGE_READ_ONLY, true);
            }
            if(_.isEmpty(state.walkMeVarReference)) {
                let walkMeVars = setWalkMeVariables(settings, getters.tenantName);
                commit(AUTH_MUTATIONS.SET_WALK_ME_VAR_REFERENCE, walkMeVars);
            }
            return settings;
        }
        catch(error) {
            commit(SET_ERROR, error.errorMessage || error.message, { root: true });
            throw error;
        }
    },

    async [AUTH_ACTIONS.LOGOUT] (context, { redirectUrl=null, caller=null }={ redirectUrl: null, caller: null }) {
        //RQO-28613 - TG - Bypass logout action if attempting to force a logout when an error occurs refreshing the token.
        const licenseStore = useLicenseStore();
        if(caller === "IdentityService.refreshToken" && licenseStore.checkFeature("bypassForcedLogoutOnError")) return;

        //if the caller is the rest service, then the last API call resulted in a 401 error
        let isFromApiCall = caller === "HttpWrapperClass.HandleRequestProcessing";

        try {
            // This is true when the user is validated through Identity AND tenant.
            let isSessionValid = context.getters.isSessionValid;
            if(!isSessionValid) return true;

            if(context.state.isAuthenticated && !isFromApiCall)
                await ActiveUserApi.logoff();
        }
        finally {
            context.commit(AUTH_MUTATIONS.PURGE_AUTH);
            Identity.logOut(redirectUrl);
        }
        return true;
    },

    async [AUTH_ACTIONS.UPDATE_TOKEN] (context, system) {
        try {
            let token = await AuthenticationApi.setSystem(system.systemId);
            context.commit(AUTH_MUTATIONS.SET_AUTH, token);
            context.commit(AUTH_MUTATIONS.SET_SYSTEM, system);
        }
        catch(error) {
            context.commit(SET_ERROR, error.errorMessage || error.message, { root: true });
        }
    },
    async [AUTH_ACTIONS.GET_CURRENT_SYSTEM](context){
        if(!_.isNil(state.system.systemId)) return;
        let system = await SystemsApi.getCurrent();
        context.commit(AUTH_MUTATIONS.SET_SYSTEM, system);
    },
    [AUTH_ACTIONS.GET_USER_REGIONS](context) {
        let userId = _.getNumber(context, "state.session.userId", null) || 0;
        if(userId === 0) return _.get(context, "state.session.regions", null) || [];
        return ACTION_WITH_COMMIT(context, UsersApi.getUserRegions(userId), AUTH_MUTATIONS.SET_USER_REGIONS);
    }
};

const mutations = {

    // Keycloak
    [AUTH_MUTATIONS.SET_IDENTITY_AUTH] (state, value) {
        state.identityToken = _.get(value, 'token', "");
        state.identityTokenParsed = _.get(value, 'tokenParsed', {});
        state.isAuthenticated = !!state.identityToken;
    },

    [AUTH_MUTATIONS.SET_AUTH] (state, value) {
        JwtService.saveToken(value);
        state.error = null;
    },

    [AUTH_MUTATIONS.SET_SYSTEM] (state, value) { state.system = value; },
    [AUTH_MUTATIONS.SET_SESSION] (state, value) { state.session = value; },
    [AUTH_MUTATIONS.SET_SESSION_USER] (state, value) { state.session.user = value; },
    [AUTH_MUTATIONS.SET_USER_DICTIONARY] (state, value) { state.session.user.dictionaryWords = value; },
    [AUTH_MUTATIONS.SET_USER_REGIONS] (state, value) { state.session.regions = value },
    [AUTH_MUTATIONS.SET_WALK_ME_VAR_REFERENCE] (state, value) { state.walkMeVarReference = value },

    [AUTH_MUTATIONS.PURGE_AUTH] (state) {
        state.session = {};
        state.system = {};
        state.error = null;
        JwtService.destroyToken();

        // Keycloak
        state.identityToken = "";
        state.identityTokenParsed = {};
        state.isAuthenticated = false;
    }
};

export default { state, getters, actions, mutations };
